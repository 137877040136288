import Web3 from 'web3'
import cardFactoryAbi from '@/abi/nftFactoryAbi'
import cardStakingAbi from '@/abi/nftStakingAbi'
import nftDividendAbi from '@/abi/nftDividendAbi'
import nftExchangeAbi from '@/abi/nftExchangeAbi'
import nftSettingsAbi from '@/abi/nftSettingsAbi'
import ERC20 from '@/abi/ERC20.json'

const ethereum = window.ethereum || null
const web3 = new Web3(ethereum)

export const maxVal = '0xffffffffffffffffffffffffffffffffffffffff'

// pro
export const usdt_address = '0xe9e7cea3dedca5984780bafc599bd69add087d56'
export const amt_address = '0x4ce5f6Bf8e996Ae54709C75865709ACA5127Dd54'

export const settings_address = '0xdf5dce4d6e0663c8d771b6cfeeabd5fe4ade821d'
export const dividend_address = '0xf58819D4eB225a7423a31F7136000D9920f954BD'
export const factory_address = '0x8C6976606AFd1E6B899568343Aac81994EB214e2'
export const exchange_address = '0x9f60353A0FF56aC4a27d9AEd5eddEc6713c858DC'
export const staking_address = '0xd047542358Ef1A7705C95a7C0189b5dD410174a7'

// pro v1.0.1
// export const usdt_address = '0xe9e7cea3dedca5984780bafc599bd69add087d56'
// export const amt_address = '0x4ce5f6Bf8e996Ae54709C75865709ACA5127Dd54'
//
// export const settings_address = '0x696418f987B768C35d238019B3Db460F8DdD0E2A'
// export const dividend_address = '0x39CB121cAC258299AcFadf27970e2775FCFe78a5'
// export const factory_address = '0x5fd5Eb00BdB6d8c384177019aC6c52Bb1e6900FD'
// export const exchange_address = '0xE6b0dF977279283385900965Bf61aB739f94D30C'
// export const staking_address = '0x5F979740baF2A1dD6cb73516e27203d54ec91d13'

// dev
// export const usdt_address = '0x51Eefe519EaB51f2c260b4B120BA532d29a1714d'
// export const amt_address = '0xcd1468D4ac070dC0AF75a47b3df2ecbE4178E7A2'
//
// export const settings_address = '0x0bd73646e68467938508f89DB421B25f3c641605'
// export const dividend_address = '0x06B8951E22567EC395235F3AA90d849e265474A5'
// export const factory_address = '0xDF13dd6bd24D654eCDC14F0aeaf3b940920e0A89'
// export const exchange_address = '0xaFdf19B96269687C105D9a6C268A93A7c14630e3'
// export const staking_address = '0x84cAf2BcC8Efe467F6aAdA7a7d59C99f0fA6A2bA'

export const cardFactory = new web3.eth.Contract(cardFactoryAbi, factory_address)
export const usdtContract = new web3.eth.Contract(ERC20, usdt_address)
export const amtContract = new web3.eth.Contract(ERC20, amt_address)
export const cardStakingContract = new web3.eth.Contract(cardStakingAbi, staking_address)
export const dividendStakingContract = new web3.eth.Contract(nftDividendAbi, dividend_address)
export const settingsContract = new web3.eth.Contract(nftSettingsAbi, settings_address)
export const exchangeContract = new web3.eth.Contract(nftExchangeAbi, exchange_address)

export function getCardType (tokenId) {
	const index = tokenId % 256
	const quality = (tokenId >>> 8) % 256
	return `${quality}-${index}`
}

/**
 * 授权
 */
export async function checkApprove (from, erc20Addr, spender, isApprove) {
	const contract1 = new web3.eth.Contract(ERC20, erc20Addr)
	const approveBalance1 = await contract1.methods.allowance(from, spender).call()
	const hasApprove = Number(web3.utils.fromWei(approveBalance1, 'ether')) > 5000
	if (hasApprove) {
		return true
	} else {
		if (isApprove) {
			if (!hasApprove) {
				await contract1.methods.approve(spender, maxVal).send({
					from,
				})
			}
		} else {
			return false
		}
	}
}

/**
 * usdt相关
 */
export const getUsdtAmt = async (user) => {
	const res = await usdtContract.methods.balanceOf(user).call()
	return Number(web3.utils.fromWei(res, 'ether')).toFixed(8)
}

/**
 * usdt相关
 */
export const getAMTAmt = async (user) => {
	const res = await amtContract.methods.balanceOf(user).call()
	return Number(web3.utils.fromWei(res, 'ether')).toFixed(8)
}

/**
 * nft factory相关
 */
export const myCards = async (address) => {
	const arr = []
	const map = {}
	try {
		const res = await cardFactory.methods.getUserTokens(address, 100000, 1).call()
		const {
			ids,
			qualities,
			indexs,
		} = res
		const totalSize = res.ids.length
		for (let i = 0; i < res.ids.length; i++) {
			const quality = qualities[i]
			const index = indexs[i]
			const key = `${quality}-${index}`
			if (!map[key]) {
				map[key] = [ids[i]]
			} else {
				map[key].push(ids[i])
			}
		}
		Object.keys(map).map(function (key, index) {
			arr.push({
				key,
				num: map[key].length,
				ids: map[key],
				rate: Number(map[key].length * 100 / totalSize).toFixed(2),
			})
		})
		arr.sort((a, b) => {
			const aa = a.split('-')
			const bb = b.split('-')
			return aa[0] * 100 + aa[1] > bb[0] * 100 + bb[1]
		})
	} catch (e) {
	}
	return arr
}

/**
 * 购买UR类型卡牌，花费100u,输入为愿意购买的类型
 */
export const buyUr = async (from, index) => {
	await checkApprove(from, usdt_address, factory_address, true)
	return await cardFactory.methods.buyUr(index).send({ from })
}

/**
 * 获取ur 剩余数量
 * @returns {Promise<*>}
 */
export const getUrInfo = async () => {
	return await settingsContract.methods.getUrInfo().call()
}

/**
 * 查询奖金池额度
 */
export const rewardAmt = async () => {
	const bal = await usdtContract.methods.balanceOf(factory_address).call()
	return Number(web3.utils.fromWei(bal, 'ether')).toFixed(4)
}

/**
 * 获得邀请别人的资格
 */
export const beInviter = async (from) => {
	await checkApprove(from, amt_address, factory_address, true)
	return await cardFactory.methods.beInviter().send({ from })
}

/**
 * 是否有邀请别人的资格,没有就不显示邀请链接
 */
export const isInviter = async (from) => {
	return await cardFactory.methods.canBeInviter(from).call()
}

export const myCardNum = async (address) => {
	return await cardFactory.methods.balanceOf(address).call()
}

export const cardTransferFrom = async (from, to, tokenId) => {
	const res = await cardFactory.methods.transferFrom(from, to, tokenId).send({ from })
	console.log('transferFrom', res)
	return res
}

/**
 * 批量转nft
 */
export const batchCardTransferFrom = async (from, to, tokenIds) => {
	console.log(from, to, tokenIds)
	const res = await cardFactory.methods.batchTransfer(to, tokenIds).send({ from })
	console.log('batchCardTransferFrom', res)
	return res
}

export const approveNftAll = async (from, to, isApprove) => {
	console.log('授权')
	console.log(from, to)
	const result = await cardFactory.methods.isApprovedForAll(from, to).call()
	if (result) {
		return true
	}
	if (isApprove) {
		await cardFactory.methods.setApprovalForAll(to, true).send({ from })
		return true
	}
	return false
}

/**
 * 获取当前购买盒子价格,多个就是一起的价格，1个就是当前一个价格
 */
export const getBoxPrice = async (num) => {
	const res = await cardFactory.methods.getBuyAmt(num).call()
	return Number(web3.utils.fromWei(res, 'ether')).toFixed(6)
}

export const approveStakingPool = async (from) => {
	await cardFactory.methods.setApprovalForAll(staking_address, true).send({ from })
}

export const isApproveStakingPool = async (from) => {
	return await cardFactory.methods.isApprovedForAll(from, staking_address).call()
}

// GUD卡牌销毁获取奖金
export const swapForBonuses = async (from, tokenId) => {
	return await cardFactory.methods.swapForBonuses(tokenId).send({ from })
}

export const bonusesList = async () => {
	const data = await cardFactory.methods.getBonusesList(100, 1).call()
	const resp = []
	for (const ele of data) {
		const info1 = ele.info1
		const info2 = ele.info2
		const tokenId = info1.substr(0, 26)
		const address = '0x' + info1.substr(-40)
		const time = Web3.utils.hexToNumber(info2.substr(0, 34))
		const amount = Web3.utils.fromWei('0x' + Web3.utils.hexToNumber(info2.substr(34)), 'ether')
		const type = getCardType(Number(tokenId))
		resp.push({
			tokenId,
			address,
			time,
			amount,
			type,
		})
	}
	return resp
}

// 合成卡牌
export const combine = async (from, ids, targetGod = 0) => {
	const result = await cardFactory.methods.combine(ids, targetGod).send({ from })
	const { events } = result
	const { NewCard } = events
	const { returnValues } = NewCard
	const tokenId = Number(returnValues.tokenId)
	const index = tokenId % 256
	const quality = (tokenId >>> 8) % 256
	return {
		id: returnValues.tokenId,
		desc: `${quality}-${index}`,
	}
}

export const fillCardList = (map, type, cards) => {
	const {
		ids,
		qualities,
		indexs,
	} = cards
	for (let i = 0; i < cards.ids.length; i++) {
		const quality = qualities[i]
		const index = indexs[i]
		const key = `${quality}-${index}`
		if (!map[key]) {
			map[key] = {
				[type]: [ids[i]],
			}
		} else if (!map[key][type]) {
			map[key][type] = [ids[i]]
		} else {
			map[key][type].push(ids[i])
		}
	}
	return map
}

export const myTotalCards = async (address, count) => {
	const arr = []
	let map = {}
	let cards1 = {}
	let cards2 = {}
	let cards3 = {}
	try {
		try {
			cards1 = await cardStakingContract.methods.getUserStakingTokens(address, count, 1).call()
			map = fillCardList(map, 'staking', cards1)
		} catch (e) {
			console.info(e)
		}
		try {
			cards2 = await cardFactory.methods.getUserTokens(address, count, 1).call()
			map = fillCardList(map, 'available', cards2)
		} catch (e) {
			console.info(e)
		}
		try {
			cards3 = await dividendStakingContract.methods.getUserStakingTokens(address, count, 1).call()
			map = fillCardList(map, 'dividend', cards3)
		} catch (e) {
			console.info(e)
		}
		Object.keys(map).map(function (key, index) {
			arr.push({
				key,
				...map[key],
				num: (map[key].available ? map[key].available.length : 0) + (map[key].staking ? map[key].staking.length : 0) +
					(map[key].dividend ? map[key].dividend.length : 0),
			})
		})
		arr.sort((a, b) => {
			const aa = a.split('-')
			const bb = b.split('-')
			return aa[0] * 100 + aa[1] > bb[0] * 100 + bb[1]
		})
	} catch (e) {
		console.info(e)
	}
	console.log('cards', arr)
	return arr
}

/**
 * nft质押挖矿相关
 */

export const myStakingCards = async (address) => {
	const arr = []
	const map = {}
	try {
		const res = await cardStakingContract.methods.getUserStakingTokens(address, 10000, 1).call()
		const {
			ids,
			qualities,
			indexs,
		} = res
		const totalSize = res.ids.length
		for (let i = 0; i < res.ids.length; i++) {
			const quality = qualities[i]
			const index = indexs[i]
			const key = `${quality}-${index}`
			if (!map[key]) {
				map[key] = [ids[i]]
			} else {
				map[key].push(ids[i])
			}
		}
		Object.keys(map).map(function (key, index) {
			arr.push({
				key,
				num: map[key].length,
				ids: map[key],
				rate: Number(map[key].length * 100 / totalSize).toFixed(2),
			})
		})
		arr.sort((a, b) => {
			const aa = a.split('-')
			const bb = b.split('-')
			return aa[0] * 100 + aa[1] > bb[0] * 100 + bb[1]
		})
	} catch (e) {}
	return arr
}

/**
 * 质押池
 */

// 质押
export const nftStakingDeposit = async (from, ids) => {
	await approveNftAll(from, staking_address, true)
	await cardStakingContract.methods.deposit(ids).send({ from })
}

// 销毁
export const nftStakingBurn = async (from, ids) => {
	await approveNftAll(from, staking_address, true)
	await cardStakingContract.methods.stakeBurnTokens(ids).send({ from })
}

// 提取
export const nftStakingWithdraw = async (from, ids) => {
	// await approveNftAll(from, staking_address, true)
	await cardStakingContract.methods.withdraw(ids).send({ from })
}

// 获取销毁收益
export const pendingBatch = async (_tokenIds) => {
	return await cardStakingContract.methods.pendingBatch(_tokenIds).call()
}

// 质押池信息
export const stakingPoolInfo = async () => {
	return await cardStakingContract.methods.totalSupply().call()
}

/**
 * 分红池
 */

// 分红池奖金
export const nftDividendPoolAmount = async () => {
	const bal = await usdtContract.methods.balanceOf(dividend_address).call()
	return Number(web3.utils.fromWei(bal, 'ether')).toFixed(4)
}

// 质押
export const nftDividendDeposit = async (from, ids) => {
	await approveNftAll(from, dividend_address, true)
	await dividendStakingContract.methods.deposit(ids).send({ from })
}

// 取回卡牌
export const nftDividendWithdraw = async (from, ids) => {
	await approveNftAll(from, dividend_address, true)
	await dividendStakingContract.methods.withdraw(ids).send({ from })
}

// 获取分红收益
export const dividendIncome = async (from) => {
	return await dividendStakingContract.methods.pending(from).call()
}

// 领取分红收益
export const receiveDividendIncome = async (from) => {
	await dividendStakingContract.methods.reclaimStakingReward(0, 0).send({ from })
}

// 获取分红池质押列表
export const getDividendCards = async (from) => {
	const arr = []
	const map = {}
	try {
		const res = await dividendStakingContract.methods.getUserStakingTokens(from, 100000, 1).call()
		const {
			ids,
			qualities,
			indexs,
		} = res
		const totalSize = res.ids.length
		for (let i = 0; i < res.ids.length; i++) {
			const quality = qualities[i]
			const index = indexs[i]
			const key = `${quality}-${index}`
			if (!map[key]) {
				map[key] = [ids[i]]
			} else {
				map[key].push(ids[i])
			}
		}
		Object.keys(map).map(function (key, index) {
			arr.push({
				key,
				num: map[key].length,
				ids: map[key],
				rate: Number(map[key].length * 100 / totalSize).toFixed(2),
			})
		})
		arr.sort((a, b) => {
			const aa = a.split('-')
			const bb = b.split('-')
			return aa[0] * 100 + aa[1] > bb[0] * 100 + bb[1]
		})
	} catch (e) {
	}
	return arr
}

export const amtINfo = async (from) => {
	let balance = await amtContract.methods.balanceOf(from).call()
	balance = Number(web3.utils.fromWei(balance, 'ether')).toFixed(8)
	const { total } = await cardStakingContract.methods.pending(from).call({ from })
	const pending = Number(web3.utils.fromWei(total, 'ether')).toFixed(8)
	return {
		balance,
		pending,
	}
}

/**
 * 买卖相关
 */

/**
 * 获取所有买卖列表
 */
export const orderList = async () => {
	const _length = await exchangeContract.methods.getOrdersLength().call()
	const _page = parseInt((Number(_length) + 1000 - 1) / 1000)
	let orders = []
	for (let i = 0; i < _page; i++) {
		const res = await exchangeContract.methods.getOrders(1000, i + 1).call()
		orders = orders.concat(res)
	}
	const resp = []
	for (let i = 0; i < orders.length; i++) {
		const order = orders[i]
		resp.push({
			...order,
			price: web3.utils.fromWei(order.price, 'ether'),
			type: getCardType(order.tokenId),
			index: i,
		})
	}
	return resp
}

// 卖，挂单
export const sell = async (from, id, price) => {
	await approveNftAll(from, exchange_address, true)
	console.log('sell', from, id, price)
	await exchangeContract.methods.sell(0, id, web3.utils.toWei(price, 'ether')).send({ from })
}

// 取消
export const cancel = async (from, index) => {
	await approveNftAll(from, exchange_address, true)
	await exchangeContract.methods.cancelOrder(index).send({ from })
}

/**
 * 买
 */
export const buy = async (from, index, id) => {
	await exchangeContract.methods.buy(index, id).send({ from })
}

/**
 * 交易授权判断
 */
export const transactionIsApprove = async (from) => {
	return await checkApprove(from, amt_address, exchange_address, false)
}

/**
 * 交易授权
 */
export const transactionApprove = async (from) => {
	return await checkApprove(from, amt_address, exchange_address, true)
}

/**
 * 获取所有竞拍列表
 */
export const auctionList = async () => {
	const result = await exchangeContract.methods.getLiveAuctions(1000, 1).call()
	const orders = result.result
	const indexs = result.auctionIndex
	const liveIndexs = result.liveIndex
	const resp = []
	const cuur = Math.floor(new Date().getTime() / 1000)
	for (let i = 0; i < orders.length; i++) {
		const order = orders[i]
		if (order.auctionState !== '0') {
			continue
		}
		resp.push({
			...order,
			price: web3.utils.fromWei(order.price, 'ether'),
			startPrice: web3.utils.fromWei(order.startPrice, 'ether'),
			buyoutPrice: web3.utils.fromWei(order.buyoutPrice, 'ether'),
			type: getCardType(order.tokenId),
			index: indexs[i],
			liveIndex: liveIndexs[i],
			isEnd: Number(order.auctionEnd) < cuur,
		})
	}
	return resp.sort((a, b) => Number(b.startTime) - Number(a.startTime))
}

// 创建竞拍
export const createAuction = async (from, id, price, buyPrice) => {
	await approveNftAll(from, exchange_address, true)
	await exchangeContract.methods.launchAuction(id, 0, 5, web3.utils.toWei(price, 'ether'), web3.utils.toWei(buyPrice, 'ether')).send({ from })
}

// 出价
export const bid = async (from, index, id, price) => {
	const is = await exchangeContract.methods.needFillAuctionHistory(index).call()
	await exchangeContract.methods.bid(index, id, web3.utils.toWei(price, 'ether'), is).send({ from })
}

// 一口价
export const buyout = async (from, index, id) => {
	console.info(from, index, id)
	await exchangeContract.methods.buyout(index, id).send({ from })
}

// 成交
export const end = async (from, index, id) => {
	await exchangeContract.methods.end(index, id).send({ from })
}

// 取消
export const redeem = async (from, index) => {
	await exchangeContract.methods.redeem(index).send({ from })
}

// 出价历史
export const bidList = async (index) => {
	const resp = []
	const bids = await exchangeContract.methods.getAuctionBids(index, 1000, 1).call()
	for (const bid of bids) {
		const hex = web3.utils.numberToHex(bid)
		resp.push({
			address: hex.substr(0, 42),
			amount: web3.utils.fromWei('0x' + hex.substr(-24), 'ether'),
		})
	}
	return resp
}
